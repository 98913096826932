import {Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Broadcast} from 'src/app/models/Broadcast';
import {PENDING} from 'src/app/models/BroadcastStatus';
import {User} from 'src/app/models/User';
import {ToastService} from 'src/app/shared/components/toast/toast.service';
import {PaginatedResult} from 'src/app/shared/model/paginated-result';
import {AuthService} from 'src/app/shared/services/auth.service';
import {BroadcastsService} from 'src/app/shared/services/broadcasts.service';
import {ADMINISTRATOR} from 'src/app/shared/services/user.service';
import {LoadingService} from '../../shared/components/loading/loading.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-sms-validation',
  templateUrl: './sms-validation.component.html',
  styleUrls: ['./sms-validation.component.scss']
})
export class SmsValidationComponent implements OnInit, OnDestroy {
  @ViewChild('modalBroadcast', {static: true}) modalBroadcast: ElementRef<any>;
  @ViewChild('modalAccept', {static: true}) modalAccept: ElementRef<any>;

  @Input() form: UntypedFormGroup;

  reason: string;
  broadcast: Broadcast;
  startDate: Date;
  endDate: Date;

  invalidTime: boolean;
  pendingOnly = true;
  autoRefreshController = null;
  users: User[];

  page = 1;
  pageSize = 10;
  originalUser: User;
  user: User;
  loading: boolean;
  result: PaginatedResult<Broadcast[]>;
  subscriptions: Subscription[] = [];

  @ViewChild('modalValidation')
  modalValidation: TemplateRef<any>;
  modalValidationRef: any;

  broadcastForm: UntypedFormGroup;

  get administrator(): boolean {
    return this.originalUser.profile_id === ADMINISTRATOR;
  }

  constructor(
    private broadcastsService: BroadcastsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private authService: AuthService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private loadginService: LoadingService
  ) { }

  ngOnInit(): void {
    this.broadcastForm = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      text: [''],
      author: [''],
      orderBy: ['created_at'],
      invertedOrder: [false],
    });

    this.user = this.authService.getUser();

    this.route.queryParams.subscribe(params => {
      this.page = params.page || this.page;
      this.pageSize = params.per_page || this.pageSize;

      this.broadcastForm.patchValue(params);

      if (params.startDate) {
        this.startDate = new Date(`${params.startDate} 00:00:00`);
        this.broadcastForm.patchValue({ startDate: this.startDate });
      } else {
        this.startDate = null;
        this.broadcastForm.patchValue({ startDate: null });
      }

      if (params.endDate) {
        this.endDate = new Date(`${params.endDate} 23:59:59`);
        this.broadcastForm.patchValue({ endDate: this.endDate });
      } else {
        this.endDate = null;
        this.broadcastForm.patchValue({ endDate: null });
      }

      this.loading = true;
      this.listPaginated();
    });

    this.subscriptions.push(
      this.authService.userChanged.subscribe(() => this.listPaginated()),
      this.broadcastsService.importFinished.subscribe(() => this.listPaginated())
    );

    this.autoRefreshController = setInterval(() => this.listPaginated() , 10 * 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    if (this.autoRefreshController) {
      clearInterval(this.autoRefreshController);
      this.autoRefreshController = null;
    }
  }

  getTitle(broadcast: Broadcast): string {
    let title = broadcast.name;
    title += ` - ${broadcast.broadcast_type?.name} `;
    title += `(${broadcast.leads_count})`;
    return title;
  }

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

  listPaginated(): void {
    const filter: any = {...this.broadcastForm.value};

    this.user = this.authService.getUser();
    if (this.user && !this.user.validated && !this.administrator) {
      return;
    }

    this.originalUser = this.authService.getUser(true);

    if (filter.startDate) {
      filter.startDate = this.formatDate(filter.startDate);
    }
    
    if (filter.endDate) {
      filter.endDate = this.formatDate(filter.endDate);
    }
    
    if (this.loading) {
      this.loadginService.start();
    }
    
    if (filter.invertedOrder) {
      filter.orderDirection = filter.invertedOrder;
    }
    
    filter.pendingOnly = this.pendingOnly;

    this.subscriptions.push(
      this.broadcastsService
        .getAllBroadcastsToValidate(this.page, this.pageSize, filter)
        .pipe(finalize(() => {
          this.loading = false;
          this.loadginService.stop();
        }))
        .subscribe(result => this.result = result)
    );


    this.broadcastForm.get('startDate').valueChanges.subscribe(() => this.validatePeriod());
    this.broadcastForm.get('endDate').valueChanges.subscribe(() => this.validatePeriod());
  }

  validatePeriod(): void {
    const startDate = this.broadcastForm.get('startDate').value;
    const endDate = this.broadcastForm.get('endDate').value;

    this.invalidTime = false;
    if (startDate && endDate) {
      this.invalidTime = startDate > endDate;
    }
  }

  invertOrder() {
    const newValue = !this.broadcastForm.get('invertedOrder').value;

    this.broadcastForm.patchValue({invertedOrder: newValue})

    this.listPaginated()
  }

  pageChanged(page: any): void {
    const queryParams: any = { page: page || this.page, pageSize: this.pageSize, ...this.broadcastForm.value };

    if (queryParams.startDate) {
      queryParams.startDate = queryParams.startDate.toISOString().split('T')[0];
    }

    if (queryParams.endDate) {
      queryParams.endDate = queryParams.endDate.toISOString().split('T')[0];
    }

    this.listPaginated();
  }

  isPendingReview(broadcast: Broadcast): boolean {
    return broadcast.broadcast_status_id === PENDING;
  }

  approve(broadcast: Broadcast): void {
    this.broadcastsService.approveBroadcast(broadcast.id).subscribe(() => {
      this.toastService.success('Broadcast aprovado com sucesso!');
      this.listPaginated();
    });
  }


  togglePending(): void {
    this.pendingOnly = !this.pendingOnly;
    this.listPaginated();
  }

  getUser(broadcast: Broadcast): string {
    return broadcast.user ? `${broadcast.user.name} (${broadcast.user.email})` : 'Desconhecido';
  }

  getMessage(broadcast: Broadcast): string {
    let message = broadcast.message;
    if (message.includes('{meu_link}')) {
      const myLink = broadcast.short_url || broadcast.url;
      message = message.replace('{meu_link}', myLink);
    }

    return message;
  }

  cancel(broadcast: Broadcast): void {
    this.reason = '';
    this.broadcast = broadcast;
    this.modalValidationRef = this.modalService.open(this.modalValidation, { size: 'lg' });
  }
  disapprove(): void {
    if (!this.reason || this.reason.trim().length === 0) {
      this.toastService.error('Justificativa é obrigatória!');
      return;
    }
    this.broadcastsService.disapproveBroadcast(this.broadcast.id, this.reason).subscribe({
      next: () => {
        this.toastService.success('Broadcast cancelado com sucesso!');
        this.listPaginated();
      },
      error: () => this.toastService.error('Erro ao cancelar o Broadcast!'),
      complete: () => this.modalValidationRef.dismiss()
    });
  }


  getDate(broadcast: Broadcast): Date | string {
    return broadcast.scheduled_date || broadcast.created_at;
  }
}
