import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IMPORTING, SCHEDULED, SENT } from '../../models/BroadcastStatus';
import { User } from 'src/app/models/User';
import { AuthService } from '../../shared/services/auth.service';
import { ADMINISTRATOR } from '../../shared/services/user.service';
import { Broadcast } from './../../models/Broadcast';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { BroadcastsService } from './../../shared/services/broadcasts.service';
import { ProviderAccountService } from 'src/app/shared/services/provider-account.service';
import { ProviderAccount } from 'src/app/models/ProviderAccount';

@Component({
  selector: 'app-broadcasts-filter',
  templateUrl: './broadcasts-filter.component.html',
  styleUrls: ['./broadcasts-filter.component.scss'],
})
export class BroadcastsFilterComponent implements OnDestroy, OnInit {
  messageBroadcast: string;
  name: string;
  providerAccountId: string;
  perPage = 10;
  text: string;
  startedMonth: Date;
  endedMonth: Date;
  startDate: string;
  endDate: string;
  invalidTime: boolean;
  orderBy = 'created_at';
  invertedOrder = false;
  page: number;
  pageSize = 10;
  user: User;
  loading: boolean;
  result: PaginatedResult<Broadcast[]>;
  providers: ProviderAccount[] = [];
  subscriptions: Subscription[] = [];
  
 
  get administrator() {
    return this.user.profile_id === ADMINISTRATOR;
  }

  constructor(
    private broadcastsService: BroadcastsService,
    private router: Router,
    private route: ActivatedRoute,      
    private providerAccountService: ProviderAccountService,    
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();
    
    this.subscriptions.push(
      this.route.queryParams.subscribe((params) => {
        this.page = params.page || this.page;
        this.pageSize = params.page_size || this.pageSize;
        this.startDate = params.started_To_Date ?? '';
        this.endDate = params.ended_To_Date ?? '';
        this.messageBroadcast = params.message || ''; 
        this.orderBy = params.orderBy || this.orderBy;
        this.invertedOrder = params.invertedOrder === 'true' || this.invertedOrder;
        this.name = params.name || '';
        this.providerAccountId = params.provider_account_id || '';        
        this.listPaginated(this.startDate, this.endDate, this.name, this.providerAccountId, this.messageBroadcast);       
      }),
    );
    
    this.subscriptions.push(
      this.providerAccountService.listAll()
        .subscribe(providerAccounts => {
          this.providers = providerAccounts;
        })
    );    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  listPaginated(startDate = null, endDate = null, name = '', providerAccountId = '', messageBroadcast=''): void {
    
    if (!startDate && this.startedMonth) {
      startDate = this.startedMonth.toISOString().split('T')[0];
    }

    if (!endDate && this.endedMonth) {
      endDate = this.endedMonth.toISOString().split('T')[0];
    }

      this.loading = true;      
      this.subscriptions.push(
        this.broadcastsService
          .getAllBroadcasts(
            this.page,
            this.pageSize,
            startDate,
            endDate,
            messageBroadcast,
            this.orderBy,
            this.invertedOrder,
            name,
            providerAccountId
          )
          .pipe(finalize(() => (this.loading = false)))
          .subscribe((result) => {
            this.result = result;
          })
      );    
  }
 
  onFilter(): void {    
    this.page = 1;
    const queryParams = {
      page: this.page,
      per_page: this.perPage,
      message: this.messageBroadcast,
      name: this.name,
      provider_account_id: this.providerAccountId,
      start_date: this.startedMonth
        ? this.startedMonth.toISOString().split('T')[0]
        : '',
      end_date: this.endedMonth
        ? this.endedMonth.toISOString().split('T')[0]
        : '',
      orderBy: this.orderBy,
      invertedOrder: this.invertedOrder,
    };    
    
    this.broadcastsService.getAllBroadcasts(
        this.page,
        this.perPage,
        queryParams.start_date,
        queryParams.end_date,
        queryParams.message,
        queryParams.orderBy,
        queryParams.invertedOrder,
        queryParams.name,
        queryParams.provider_account_id,        
      ).subscribe((response) => {
        this.result = response;        
      });    
      
  }

  getTitle(broadcast: Broadcast) {
    let title = broadcast.name;

    title += ` - ${broadcast.broadcast_type.name} `;

    if (this.isImporting(broadcast)) {
      title = '[importando...] ' + title;
    } else if (this.isCancelled(broadcast)) {
      title = '[Cancelado] ' + title;
    }

    title += `(${broadcast.leads_count})`;

    return title;
  }

  isScheduled(broadcast: Broadcast): boolean {
    return broadcast.broadcast_status_id === SCHEDULED;
  }

  isSent(broadcast: Broadcast): boolean {
    return broadcast.broadcast_status_id === SENT;
  }

  isHiddenButton(broadcast: Broadcast): boolean {
    return broadcast.broadcast_status_id !== SENT;
  }

  isImporting(broadcast: Broadcast): boolean {
    return this.broadcastsService.isImporting(broadcast);
  }

  isCancelled(broadcast: Broadcast): boolean {
    return this.broadcastsService.isCancelled(broadcast);
  }

  pageChanged(page: number): void {
    this.page = page;
    const queryParams = {
      page: this.page,
      per_page: this.perPage,
      message: this.messageBroadcast,
      name: this.name,
      provider_account_id: this.providerAccountId,
      start_date: this.startedMonth ? this.startedMonth.toISOString().split('T')[0] : '',
      end_date: this.endedMonth ? this.endedMonth.toISOString().split('T')[0] : '',
      orderBy: this.orderBy,
      invertedOrder: this.invertedOrder,
    };

    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });  
  }

  orderByChanged() {
    this.onFilter(), {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderBy: this.orderBy,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth,
      },
      queryParamsHandling: 'merge',
    };
  }

  invertOrder(): void {
    this.invertedOrder = !this.invertedOrder;
    this.onFilter(), {
      relativeTo: this.activatedRoute,
      queryParams: {
        invertedOrder: this.invertedOrder,
        startedMonth: this.startedMonth,
        endedMonth: this.endedMonth
      },
      queryParamsHandling: 'merge'
    }
  }

}
