import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgencyFilter } from 'src/app/models/AgencyFilter';
import { Notification } from 'src/app/models/Notification';
import { PartnerInfo } from 'src/app/models/PartnerInfo';
import { Twilio } from 'src/app/models/Twilio';
import { User } from 'src/app/models/User';
import { UserPayment } from 'src/app/models/UserPayment';
import { environment } from 'src/environments/environment';

import { Affiliate } from '../../models/Affiliate';
import { UserCommission } from '../../models/UserCommission';
import { UserFilter } from './../../models/UserFilter';
import { PaginatedResult } from './../model/paginated-result';
import { AuthService } from './auth.service';

export const ADMINISTRATOR = 'c7eedac4-47bc-4262-b6e3-7d242c27449f';
export const AGENCY_CLIENT = '8fe4e83a-db75-45f0-bf78-4a5e830c8101';
export const CLIENT = '4899dfa7-2462-46f1-bc45-ad4802deb0fc';
export const PARTNER_CLIENT = 'a99938fb-c4f0-463a-aecb-6d16440039fe';
export const CUSTOMER_SUCCESS = 'b4195042-6669-4a45-a753-5400b7822fd7';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  listAll(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  listPaginated(
    page: number,
    perPage: number,
    filter: UserFilter = null,
    order: string = 'users.created_at',
    inverted: boolean | number = false
  ): Observable<PaginatedResult<User[]>> {
    let url = `${environment.apiUrl}/users?page=${page}&per_page=${perPage}`;
    if (
      filter !== null &&
      (filter.text || filter.active !== null || filter.validated !== null || filter.promocode !== null || filter.promocodeId !== null)
    ) {
      url += `&filter=` + JSON.stringify(filter);
    }

    if (order) {
      url += `&order=${order}`;
    }

    url += `&inverted=${inverted}`;


    return this.http.get<PaginatedResult<User[]>>(url);
  }

  listCreditsPaginated(
    page: number,
    perPage: number,
    period?: Array<String>
  ): Observable<PaginatedResult<User[]>> {
    let url = `${environment.apiUrl}/credits-info?page=${page}&per_page=${perPage}`;
    if (period[0]) {
      url += '&start=' + period[0];
    }
    if (period[1]) {
      url += '&end=' + period[1];
    }
    return this.http.get<PaginatedResult<User[]>>(url);
  }

  search(document: string = '', email: string = ''): Observable<any> {
    let url = `${environment.apiUrl}/users/search`;
    if (document) {
      url += `?document=${document}`;
    } else if (email) {
      url += `?email=${email}`;
    }
    return this.http.get<any>(url)
  }

  signUp(user: User): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/sign-up`, user);
  }

  startPasswordReset(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/password-reset`, {
      email,
    });
  }

  setNewPassword(password: string, hash: string): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/users/password-reset/${hash}`,
      { password }
    );
  }

  verifyPasswordHash(hash: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users/password-reset/${hash}`);
  }

  save(user: User): Observable<User> {
    const original_user = this.authService.getUser(true);
    const edit_user_object = { ...user };
  
    if (user.profile_id && original_user.profile_id != ADMINISTRATOR) {
      delete edit_user_object.profile_id;
    }
  
    if (user.id) {
      return this.http.put<User>(
        `${environment.apiUrl}/users/${user.id}`,
        edit_user_object
      );
    }
  
    return this.http.post<User>(`${environment.apiUrl}/users`, edit_user_object);
  }
  

  findById(id: string): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }

  validate(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/${id}/validate`, {});
  }

  findAffiliates(
    page: number,
    perPage: number,
    filter: AgencyFilter = null
  ): Observable<PaginatedResult<Affiliate[]>> {
    let url = `${environment.apiUrl}/users/affiliates?page=${page}&per_page=${perPage}`;
    if (filter) {
      url += '&filter=' + JSON.stringify(filter);
    }
    return this.http.get<PaginatedResult<Affiliate[]>>(url);
  }

  getPartnerDashboard(): Observable<PartnerInfo> {
    return this.http.get<PartnerInfo>(
      `${environment.apiUrl}/users/partner/dashboard`
    );
  }

  getPendingCommission(): Observable<UserCommission> {
    return this.http.get<UserCommission>(`${environment.apiUrl}/users/partner/pending`);
  }

  getPayments(page: number, perPage: number): Observable<PaginatedResult<UserPayment[]>> {
    return this.http.get<PaginatedResult<UserPayment[]>>(
      `${environment.apiUrl}/users/partner/payments?page=${page}`
    );
  }

  getPendingCommissionsPaginate(page: number, per_page: number): Observable<PaginatedResult<UserCommission[]>> {
    return this.http.get<PaginatedResult<UserCommission[]>>(
      `${environment.apiUrl}/users/partner/pending?page=${page}`
    );
  }

  listWithoutReference(userId: string, filter: string): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/users/${userId}/without-reference?filter=${filter}`);
  }

  listAlerts(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${environment.apiUrl}/users/alerts?no-loading=true`);
  }

  updateAlert(id: number) {
    return this.http.put(`${environment.apiUrl}/users/alerts/${id}`, {});
  }

  updateCounters(id: string) {
    return this.http.put(`${environment.apiUrl}/users/${id}/counters`, {});
  }

  saveGlobalAccount(globalAccount: Twilio) {
    return this.http.put(`${environment.apiUrl}/users/global`, globalAccount);
  }

  getGlobalAccount(): Observable<Twilio> {
    return this.http.get<Twilio>(`${environment.apiUrl}/users/global`);
  }

  deleteGlobalAccount() {
    return this.http.delete(`${environment.apiUrl}/users/global`);
  }

  getOperationTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.baseUrl}/operation_types`);
  }
}
